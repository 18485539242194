<script>
import ApiService from "../common/service.api"
export default {
  mounted() {
    this.createWidget()
  },
  data() {
    return {
      userId: "test_axerta",
      region: "eu",
      environment: "sandbox",
      appId:
        "wAppId_afdc43bb4e41128b379a6bb5f161b82ccb03c81cda43b236a9f36ad2acae",
    }
  },
  methods: {
    loadBandyerSDK() {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src =
          "https://cdn.bandyer.com/sdk/js/chat/2.5.4/bandyer-widget.min.js"
        script.async = true
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
      })
    },
    async createWidget() {
      const userId = "test_axerta"
      const appId =
        "wAppId_afdc43bb4e41128b379a6bb5f161b82ccb03c81cda43b236a9f36ad2acae"
      const region = "eu"
      const environment = "sandbox" //note the sandbox environment exists only in eu region

      const retrieveAccessToken = async (region, environment, userId) => {
        // retrieve the access token using POST /sdk/credentials on the server side
        // const accessToken = await this.callToYourServer()
        const accessToken = await ApiService.get(
          `VideoChat/GetToken/${userId}`
        ).then((res) => {
          return res.data
        })

        return accessToken
      }

      try {
        await this.loadBandyerSDK()
        const Client = window.BandyerSDK.configure({
          appId: appId,
          region: region,
          environment: environment,
          tools: {
            chat: true,
          },
        })

        Client.on("client:access_token:is_about_to_expire", async (data) => {
          const accessToken = await retrieveAccessToken(
            region,
            environment,
            userId
          )
          const { expiresAt } = await Client.updateAccessToken(accessToken)
        })

        Client.on("client:access_token:expired", async () => {
          await Client.disconnect()
          const accessToken = await retrieveAccessToken(
            region,
            environment,
            userId
          )
          await Client.connect(userId, accessToken)
        })

        const accessToken = await retrieveAccessToken(
          region,
          environment,
          userId
        )
        await Client.connect(userId, accessToken)
      } catch (error) {
        console.error("Failed to retrieve the access token or connect:", error)
      }
    },
  },
}
</script>
